@import "../../../../scss/functions";

.block-header {
  display: flex;
  align-items: center;
  justify-content: center;
}

.block-header__title__custom {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 24px !important;
  width: 100%;
}

.title-line-fms {
  width: 70px;
  height: 3px;
  background: black;
}

.title-name-fms {
  color: #121212;
  padding: 0 10px;
  font-size: 32px;
  min-height: 20px;
  font-weight: 500;
  white-space: nowrap;
  text-transform: uppercase;
  @media screen and (max-width: 500px) {
    min-height: 20px;
    font-size: 30px;
  }
  @media screen and (min-width: 320px) and (max-width: 414px) {
    white-space: unset !important;
    text-align: center;
  }
  @media (min-width: 501px) and (max-width: 800px) {
    min-height: 20px;
  }
}

@media (max-width: breakpoint(767px)) {
  .block-header {
    display: flex;
    flex-wrap: wrap;
  }
}